<template>
  <div id="mian">
    <!-- <div class="desc">
      <h5 class="jsxx">店铺免审模式</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>进件频率(秒/次)</p>
          </td>
          <td>
            <p>冻结时间（秒）</p>
          </td>
					<td style="width:25%">
					  <p>激活状态</p>
					</td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>{{storeConfig.applyFrequency}}</p>
          </td>
          <td>
            <p>{{storeConfig.frozenFrequency}}</p>
          </td>
					<td>
            <p v-show="OFFON_EDIT != 'SYSTEM:GLOBAL_CONFIG/EDIT'"> {{ storeConfig.activateStatus==true ? "已开启" : "已关闭" }}</p>
						<p v-auth="'SYSTEM:GLOBAL_CONFIG/EDIT'">
							<el-switch v-model="storeConfig.activateStatus" @change="setStatus" active-color="#13ce66">
							</el-switch>
						</p>
					</td>
          <td>
            <p><i class="lianjie" @click="onShow(true)" v-auth="'SYSTEM:GLOBAL_CONFIG/EDIT'">编辑</i></p>
          </td>
        </tr>
      </table>
    </div> -->
    <div class="desc">
      <h5 class="jsxx">测试商户密钥管理</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>测试商户编号</p>
          </td>
          <td>
            <p>密钥</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>{{merchantConfig.testMchId}}</p>
          </td>
          <td>
            <p>{{merchantConfig.testMchKey}}</p>
          </td>
          <td>
            <p><i class="lianjie" @click="onShow(false)"  v-auth="'SYSTEM:GLOBAL_CONFIG/EDIT'">编辑</i></p>
          </td>
        </tr>
      </table>
    </div>
    <el-dialog title="店铺免审模式" :visible.sync="userPayShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">进件频率(s/次)</div>
          <el-input v-model="storeForm.applyFrequency" type="number" onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))" placeholder="输入进件频率"></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title">冻结时间(s)</div>
          <el-input v-model="storeForm.frozenFrequency" type="number"  onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))" placeholder="输入冻结时间"></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="userPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="onsubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="测试商户密钥管理" :visible.sync="hasPayShow" width="490px">
      <ul class="overAccount_ul">
        <li style="width: 300px;margin: 0 auto;">
          <div class="overAccount_ul_title">当前密钥</div>
          <el-input v-model="merchantForm.testMchKey" placeholder="密钥" disabled></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer secret-key">
        <el-button class="exitBt" style="margin-right:50px" @click="random">变 更</el-button>
        <el-button class="addBt" @click="onsubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
	import {
		getConfig,
		setConfig,
    getGeneratesecret
	} from '@/api/management/index.js'
  export default {
    data() {
      return {
				storeConfig:'',
				storeForm:'',
				merchantConfig:'',
				merchantForm:'',
        userPayShow: false, //店铺免审
        hasPayShow: false, //新增代付展示
        OFFON_EDIT: 'SYSTEM:GLOBAL_CONFIG/EDIT',
      };
    },
		created() {
			this.getDetail();
      this.listJurisdiction();
		},
    methods: {
			getDetail(){
				getConfig().then(res=>{
					if(res.resultStatus){
						this.storeConfig = res.resultData.storeConfig
						this.merchantConfig = res.resultData.merchantConfig
					}
				})
			},
			onShow(active){
				this.storeForm = JSON.parse(JSON.stringify(this.storeConfig))
				this.merchantForm = JSON.parse(JSON.stringify(this.merchantConfig))
        console.log("被编辑的店铺免审模式数据",	this.storeForm)
				if(active){
					this.userPayShow = true
				}else{
					this.hasPayShow = true
				}
			},
			// 激活状态修改
			setStatus(){
        this.$confirm('此操作将修改状态, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            storeConfig: this.storeConfig,
            merchantConfig: this.merchantConfig,
          }
          setConfig(data).then(res=>{
            if(!res.resultStatus){
              this.storeConfig.activateStatus = this.storeConfig.activateStatus?false:true
            }
          })
        }).catch(() => {
					this.storeConfig.activateStatus = this.storeConfig.activateStatus?false:true
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
			},
			// 随机
			random(){
        getGeneratesecret().then(res=>{
          if(res){
            this.merchantForm.testMchKey = res.resultData
          }
        })
				// this.merchantForm.testMchKey = this.randomRange(32)
			},
			// 随机数字
			randomRange(min, max){
			    var returnStr = "",
			        range = (max ? Math.round(Math.random() * (max-min)) + min : min),
			        arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
			 
			    for(var i=0; i<range; i++){
			        var index = Math.round(Math.random() * (arr.length-1));
			        returnStr += arr[index];
			    }
			    return returnStr;
			},
			onsubmit(){
				if( !/^[0-9]+$/.test(this.storeForm.applyFrequency)){
					this.$message.error('请输入正确的进件频率')
					return
				}
				if( !/^[0-9]+$/.test(this.storeForm.frozenFrequency)){
					this.$message.error('请输入正确的冻结时间')
					return
				}
				let data = {
					storeConfig: this.storeForm,
					merchantConfig: this.merchantForm,
				}
				setConfig(data).then(res=>{
					if(res.resultStatus){
            this.$message.success('保存成功')
						this.getDetail()
						this.userPayShow = false
						this.hasPayShow = false
					}
				})
			},
      //判断列表按钮是否有权限
      listJurisdiction(){
          const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
          if(_resources=='ALL'){
              this.OFFON_EDIT = 'SYSTEM:GLOBAL_CONFIG/EDIT';
          }else{
            if(_resources.split(';').indexOf('SYSTEM:GLOBAL_CONFIG/EDIT') !== -1 ){
              this.OFFON_EDIT = 'SYSTEM:GLOBAL_CONFIG/EDIT';
            }else{
              this.OFFON_EDIT = "";
            }
          }

      },
    }
  }
</script>
<style scoped>
 @import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}   
  .next {
    width: 120px;
    height: 32px;
    background: rgba(72, 184, 182, 1);
    border-color: #5AAFAB;
    border-radius: 3px;
    line-height: 10px;
    margin-left: 10px;
    padding: 0;
    font-size: 12px;
  }

  .back {
    width: 120px;
    line-height: 10px;
    height: 32px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #DCE0E6;
    border-radius: 3px;
    padding: 0;
    font-size: 12px;
    color: #333333;
  }

  .btn {
    width: 100%;
    text-align: center;
    height: 33px;
    margin-top: 50px;
    margin-bottom: 18px;
  }

  .overAccount_ul {
    overflow: hidden;
  }

  .overAccount_ul_title {
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    color: #999999;
    text-align: left;
  }

  .overAccount_ul .el-input,
  .overAccount_ul .el-select {
    margin-bottom: 7px;
  }

  .overAccount_ul .ul_left {
    float: left;
    width: 200px;
    margin-left: 24px;
  }

  .overAccount_ul .ul-right {
    float: right;
    width: 200px;
    margin-right: 26px;
  }

  .addBt {
    width: 60px;
    height: 32px;
    background-color: #48B8B6;
    border-color: #5AAFAB;
    color: #FFFFFF;
    font-size: 12px;
    padding: 0;
  }

  .exitBt {
    width: 60px;
    height: 32px;
    background-color: #ffffff;
    border-color: #DCE0E6;
    color: #333333;
    font-size: 12px;
    padding: 0;
  }
  .secret-key{
    display: flex;
    justify-content: center;
  }
</style>
